<template>
  <router-layout>
    <div class="page">
      <div class="header">
        <h2>{{title}}</h2>
        <div class="timeBox">
          <div>
            <span class="iconfont icon-shizhong"></span>
            <span>{{create_time}}</span>
          </div>
          <div class="like">
            <van-checkbox v-model="favorite" @click="favoriteChange">
              <template #icon="props">
                <van-icon name="like" color="#f03535" v-if="props.checked" />
                <van-icon name="like-o" v-else />
              </template>
            </van-checkbox>
            <div v-if="favorite">已收藏</div>
            <div v-else>收藏</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="body">
        <ul>
          <li>
            有效期:<span>{{valid_time}}</span>
          </li>
          <li>
            联系人:<span>{{name}} <em><a :href="'tel:'+ phone">{{phone}}</a></em>(点击号码拨打)</span>
          </li>
          <li v-show="hasImg">
            图片:
          </li>
          <li class="img" v-show="hasImg">
            <img :src="item" v-for="item,index in image" :key="index" @click="imageView(index)"/>
          </li>
        </ul>
      </div>
      <div class="dr">
        详细描述
      </div>
      <div class="detail" v-html="description">
      </div>
    </div>
  </router-layout>
</template>
<script>
import authMixin from "@/mixin/mixin";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      infoType:'',
      id:'',
      title:'',
      create_time:'',
      name:'',
      phone:'',
      valid_time:'',
      description:'',
      favorite: false,  //收藏
      image:[],
      hasImg:false
    };
  },
  mixins: [authMixin],
  created(){
    this.id = this.$route.query.id
    this.infoType = this.$route.query.infoType
    this.getDetail()
    this.getHistory()
  },
  methods:{
    getHistory() {
      //我的历史
      this.$request({
        method: "post",
        url: "/cms/history/",
        data: {
          type: this.infoType,
          fid: this.id
        }
      }).then(res => {
        console.log(res);
      });
    },
    favoriteChange() {
      if (!this.favorite) {
        console.log('去取消')
        //取消收藏
        this.$request({
          method: "post",
          url: "/cms/deleteFavorite/",
          data: {
            type: this.infoType,
            fid: this.id
          }
        }).then(res => {
          console.log(res);
        });
      } else {
        //收藏
        console.log('去收藏')
        this.$request({
          method: "post",
          url: "/cms/favorite/",
          data: {
            type: this.infoType,
            fid: this.id
          }
        }).then(res => {
          console.log(res);
        });
      }
    },
    imageView(index){
      let _this = this
      ImagePreview({
        images: this.image,
        startPosition: index
      })
    },
    getDetail(){
      this.$request({
        method: "get",
        url: "cms/promote/detail/" + this.id,
      }).then(res => {
        console.log(res)
        if(res.data){
          this.create_time = res.data.create_time
          this.title = res.data.title
          this.name = res.data.name
          this.phone = res.data.phone
          this.description = res.data.description
          this.favorite = res.data.favorite;
          if(res.data.imgs){
            this.image = res.data.imgs.split(',')
            this.hasImg = true
          }else{
            this.hasImg = false
          }
          switch (res.data.valid_time){
            case 0:
              this.valid_time = '长期有效'
              break;
            case 1:
              this.valid_time = '一周'
              break;
            case 2:
              this.valid_time = '一个月'
              break;
            case 3:
              this.valid_time = '两个月'
              break;
            case 4:
              this.valid_time = '一年'
              break;
          }
        }
      })
    }
    //method end
  }
};
</script>
<style scoped lang="scss">
@import "../../../assets/iconfont/iconfont.css";
.page {
  background: #fff;
  min-height: 100vh;
  padding-bottom: 1.5rem;
  .header {
    padding: 0.55rem 0.3rem;
    border-bottom: 1px solid #eee;
    h2 {
      font-weight: normal;
      font-size: 0.42rem;
      margin-bottom: 0.5rem;
    }
    .timeBox {
      display: flex;
      justify-content: space-between;
      color: #999;
      font-size: 0.34rem;
      height: 0.5rem;
      align-items: center;
      .iconfont {
        margin-right: 0.1rem;
      }
      .like {
        display: flex;
        align-items: center;
        .van-icon {
          font-size: 0.5rem;
          border: none;
          color: #ccc;
          margin-left: 0.05rem;
        }
        .van-checkbox__icon--checked .van-icon {
          background: transparent;
        }
      }
    }
  }
  .line {
    height: 0.2rem;
    background: #f5f5f5;
  }
  .body {
    padding: .8rem .3rem .6rem .3rem;
    li {
      color: #9e9e9e;
      margin-bottom: 0.26rem;
      font-size: 0.38rem;
      span {
        margin-left: .2rem;
        color: #333;
        font-size: 0.38rem;
        a{
          color: red;
          font-style: normal;
          font-size: .4rem;
          vertical-align: -.03rem;
          margin-right: .2rem;
        }
      }
      img{
        display: inline-block;
        width: 3.8rem;
        margin-right: .25rem;
      }
      .telphone{
        display: block;
        background:#567cf3;
        text-align: center;
        line-height: .8rem;
        width: 2.8rem;
        border-radius: .8rem;
        color: #fff;
      }
    }
  }
  .dr{
      background: #f5f5f5;
      color: #777;
      padding:.3rem .3rem;
      font-size: .38rem;
    }
  .detail{
    padding: .5rem .3rem;
    font-size: .38rem;
    line-height: .6rem;
  }
}
</style>